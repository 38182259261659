.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.swal2-actions{
  margin-top: 0;
}

.swal2-loader{
  border-top: 2px solid #AD49E1;
  border-bottom: 2px solid #AD49E1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navBarHeader{
  margin-top: 10%;
  margin-bottom: 10%;
}

.navBarFooter{
  margin-top: auto;
  margin-bottom: 5%;
  color: #7D33A3;
  cursor: pointer;
  align-items: center;
  height: 4vh
}

.navBarFooterText{
  display: inline-flex;
  margin-left: 3px;
  margin-top: 3px;
  height: 100%;
  align-items: center;
}

pre{
  margin: 5px;
}

#objetodestino{
  margin-bottom: 20px;
}

.logo{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.logoText{
  font-size: 20px;
  align-items: center;
}

.logoholder{
  width: 50%;
}

.SlideBarButtonDiv{
background-color: white;
}


.slidebarname{
  font-size: 0.8rem;
}

.slidebaricon{
  font-size: 1.8em;
  align-items: center;
  display: flex;
}
a {
  text-decoration: none;
}
.SlideBarButtonDiv {
  position: relative; /* Necessário para z-index funcionar */
}

.SlideBarButton {
  text-align: left;
  padding-left: 20%;
  width: 100%;
  height: 50px;
  gap: 4%;
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255); /* Cor de fundo padrão */
  border: 0;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(to right, rgba(173, 73, 225, 0), #AD49E1 50%, rgba(173, 73, 225, 0));
  border-image-slice: 1;
  color: #453947;
  font-weight: bold;
  transition: all 0.3s ease-in-out; /* Animações suaves */
  position: relative; /* Necessário para z-index funcionar */
}

/* Estilo para o botão ativo */
.SlideBarButton.btn-active {
  background-color: #AD49E1; /* Cor de fundo quando o botão está ativo */
  border-bottom: 2px solid #7D33A3; /* Borda inferior mais escura quando ativo */
  color: #FFFFFF; /* Cor do texto quando o botão está ativo */
  z-index: 1; /* Garante que o botão ativo esteja acima dos outros */
  font-size: 1.2em; /* Aumenta o tamanho da fonte quando ativo */
}

/* Estilo de hover para SlideBarButton */
.SlideBarButton:hover {
  border-image: linear-gradient(to right, #AD49E1, #AD49E1); /* Preenche completamente a borda */
  color: #320C52; /* Altera a cor do texto */
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #F5E1FF); /* Gradiente de fundo */
  background-size: 200% 100%; /* Configura o tamanho da animação de fundo */
  background-position: right; /* Define a posição inicial */
  font-size: 1.2em; /* Aumenta o tamanho da fonte */
}

/* Garantir que o estilo de hover não afete o botão ativo */
.SlideBarButton.btn-active:hover {
  background: #AD49E1; /* Mantém a cor de fundo do botão ativo */
  border-bottom: 2px solid #7D33A3; /* Mantém a borda inferior do botão ativo */
  color: #FFFFFF; /* Mantém a cor do texto do botão ativo */
  font-size: 1.2em; /* Mantém o tamanho da fonte */
}

button{
  cursor: pointer;
}

.editableThing{
  align-items: center;
}

.bigSelect{
  height: 35px;
}

.pageloginBg{
  background-color: #7D33A3;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
}

#formLogin{
  width: 30%;
  margin: auto;
  gap: 2%;
  display: grid;
  height: 100%;
  margin-top: 5%;
  background-color: white;
  padding: 2%;
  border-radius: 5%;
  border: 1px solid #7D33A3;
}
.form-group{
  display: flex;
  justify-content: space-between;
}

.additional-options{
  margin-top: 5%;
  display: flex;
  justify-content: space-around;
}

.adopt{
color: #7D33A3;
}

.btn-submit2{
  margin-top: 1%;
  background-color: #AD49E1;
  height: 50px;
  color: white;
  border: 0;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  width: 100%;
}


.btn-submit{
  margin-top: 5%;
  background-color: #AD49E1;
  height: 50px;
  color: white;
  border: 0;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;

}

.btn-submit:disabled {
  background-color: #cccccc; /* Cor de fundo quando desativado */
  color: #666666; /* Cor do texto quando desativado */
  cursor: not-allowed; /* Cursor padrão quando desativado */
  opacity: 0.6; 
}

.btnSubmitItems svg{
font-size: 1.6rem;
}

.btnSubmitItems{
  display: inline-flex;
  gap: 11%;
  align-items: center;
  text-align: center;
}

input{
  background-color: rgb(245, 245, 245);
}

.navbar{
  position: fixed;
  top: 0;
  width: 20%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: grid;
  justify-content: space-between;
}

.inputIp{
  width: 447px;
  margin-right: auto;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

select{
  width: 50px;
  height: 25px;
  outline: 0;
  border: 0;
}

.divson{
  width: 15%;
}

.divson2{
  margin-left: auto;
  text-align: right;
}

.divsonPlus{
  width: 130px;
}

.divsondesc{
  font-style: italic;
  color: #a7a7a7;
}

::placeholder{
  color: #a7a7a7;
  font-style: italic;
}

input{
  height: 25px;
  width: 500px ;
  border-radius: 2px;
  border:0;outline:0;
}


.btn-choice{
  width: 100%;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 40px;
}


.btn-choice:hover {

}

h4{

margin: auto;
margin-top: 20px;
margin-bottom: 0;
}


#action, #localidade, #interfaceorigem, #interfacedestino, #nat{
  width: 85%;
}

.editableText{
  width: 500px;
}

.blcokedInput{
  cursor: not-allowed;
}

.h2Obj{
  margin: 0;
  display: inline-flex;
  text-align: center;
  justify-content: center;
}

.listaEditableLocalidades{
  width: 580px;
  margin: auto;
  display: grid;
  gap: 8%;
}

.blockedInput{
  cursor: not-allowed;
  color: #888888;
  font-weight: bold;
}

span{
  color: #7D33A3;
}


.btn-addLocalidade, .btn-addInterface{
  background-color:#ffffff;
  border: 0;
  color: #26773e;
  border: 1px solid #26773e;
  border-radius: 2px;
  width: 85%;
  margin: auto;
  height: 100%;
  border-radius: 5px;
}

.btn-addLocalidade:hover, .btn-addInterface:hover{
  background-color:#0f9136;
  color: white;
  font-size: 15px;
}

.EditableLocalidades, .EditableInterfaces{
  display: flex;
}

.listaEditableInterfaces{
  width: 580px;
    margin: auto;
    display: grid;
    gap: 8%;
}

.fireImg{
  width: 7%;
  height: 12%;
}

.gearImg{
  margin-left: 1%;
  width: 18%;
}

.cubeImg{
  margin-left: 1%;
  width: 10%;
}

.rocketImg{
  width: 12%;
}

.btn-active {
  background-color: #AD49E1; 
  color: white; 
}

.btn-editar {
  background-color: white;
  color: #1976D2;
  border: 1px solid #1976D2;
  border-radius: 5px;
  width: 30px;
  height: 100%;
  transition: background-color 0.3s, color 0.3s; /* Suaviza a transição */
}

.btn-editar:hover {
  background-color: #1976D2; /* Muda a cor de fundo no hover */
  color: white; /* Muda a cor do texto no hover */
}

.btn-salvar {
  background-color: white;
  color: #388E3C;
  border: 1px solid #388E3C;
  border-radius: 5px;
  width: 30px;
  height: 100%;
  transition: background-color 0.3s, color 0.3s;
}

.btn-salvar:hover {
  background-color: #388E3C;
  color: white;
}

.btn-excluir {
  background-color: white;
  color: #C62828;
  border: 1px solid #C62828;
  border-radius: 5px;
  width: 30px;
  height: 100%;
  transition: background-color 0.3s, color 0.3s;
}

.btn-excluir:hover {
  background-color: #C62828;
  color: white;
}

.btn-cancelar {
  background-color: white;
  color: #474747;
  border: 1px solid #474747;
  border-radius: 5px;
  width: 30px;
  height: 100%;
  transition: background-color 0.3s, color 0.3s;
}

.btn-cancelar:hover {
  background-color: #474747;
  color: white;
}

  .divDosBotoes{
    width: 80px;
    display: flex;
    justify-content: space-evenly;
  }

.choiceObjeto{
  width: 31%;
  margin:auto;
  display: flex;
  margin-bottom: 25px;
  gap: 2px;
  margin-top: 1.5%;
}

.off{
  display: none;
}

.bigInput{
  height: 40px;
  font-size: 15px;
  padding: 0 1%;
  width: 86.2%;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
width: 120px;
}

.formDiv{
  display: flex;
  justify-content: center;
}

.formDivDescricao{
  margin-top: 20px;
  justify-content: space-between;
}

select{
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
  padding-left: 0.5% ;
}



select:focus{
  appearance: none;
}

.loading-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.center{
  text-align: center;
  margin: auto;
  margin-top: 6%;
}

.minilogo{
  width: 25%;
  margin: 2% 0;
}

.centerDois{
  text-align: center;
  margin: auto;
  margin-top: 0;
}
.centerTres{
margin-left: auto;
text-align: center;
padding: 0;
}

.formPai{
  grid-gap: 5px;
  display: grid;
  gap: 5px;
  margin: auto;
  text-align: left;
  width: fit-content;
}

.tasks-table-container{
  margin: auto;
  max-width: 50%;
  width: 65%;
  max-width: 65%;
  margin-left: 22%;
}

.tasks-table-container {

  margin-top: 20px;
}

.tasks-table {
  width: 100%;
  border-collapse: collapse; /* Remove espaçamento entre as células */
  background-color: #f9f9f9;
  margin-bottom: 20px;
  border: 2px solid #AD49E1;
}

.tasks-table th,
.tasks-table td {
  padding: 12px 0px;
  text-align: left;
}




.tasks-table th {
  background-color: #AD49E1; /* Cor de fundo do cabeçalho */
  color: white;
  position: sticky;
  top: 0; /* Mantém o cabeçalho fixo no topo */
  z-index: 1;
}

.tasks-table tr:nth-child(even) {
  background-color: #f2f2f2; /* Cores alternadas nas linhas */
}

.tasks-table tr:hover {
  background-color: #ddd; /* Efeito de hover nas linhas */
}

.tasks-table td {
  border-bottom: 1px solid #ddd;
  color: #333; /* Cor do texto nas células */
  padding: 0; /* Remove o padding para permitir que o conteúdo ocupe toda a altura */
  height: 4.5vh /* Garante que a célula use 100% da altura */
}


.tasks-table th:first-child,
.tasks-table td:first-child {
  border-left: 0;

}

.tasks-table td{
  padding-left: 0;
 text-align: center;
}

.tasks-table th{
  border-left: 0;
  text-align: center;
}



.tasks-table td {
  border-left: 0;
  border: 1px solid rgb(212, 212, 212);
}

.tasks-table th:last-child,
.tasks-table td:last-child {
  border-right: 0;
}

.row-created-at{
  color: rgb(126, 126, 126)!important;
  font-style: italic;
}
@media only screen and (max-width: 1300px){

}

.row-actions {
  padding: 0;
  height: 100%; /* Garante que a célula use 100% da altura */
  vertical-align: middle; /* Centraliza o conteúdo verticalmente */
}

.inputWithIcon {
  position: relative;
  display: flex;
  align-items: center;
}

.questionIcon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #007bff;
}

.btn-details {
color: grey;
background-color: rgba(128, 128, 128, 0);
border: 0;
gap: 2px;
display: flex;
margin-right: auto;
}

.swal2-html-container{
  padding: 0;
}

/* Estilo de hover */
.btn-details:hover {
  border-radius: 0px;
  color: #AD49E1;
  border: 0;
}

.swal-custom-icon {
  margin-top: 0;
  width: 48px !important;
  height: 48px !important;

}

.swal2-title{
  padding: 0!important;
  font-size: 1.5rem;
}

.swal2-icon-content{
  font-size: 4vh!important;
  text-align: center;
}

.swal-custom-icon .swal2-icon-content {
  color: #AD49E1 !important; /* Cor do 'i' */
}

.swal-custom-icon.swal2-info {
  border-color: #AD49E1 !important; /* Cor do círculo ao redor do 'i' */
}

.swal-custom-confirm-button {
  background-color: #AD49E1 !important; /* Cor do botão de fechar */
  color: white !important; /* Cor do texto no botão */
}

.btn-copy{
  position: absolute; right: 5px; top: 05px; background-color: white; color: #AD49E1; border: 1px solid #AD49E1; borde-radius: 5px; cursor: pointer; padding: 10px 20px; border-radius: 5px;
}

.btn-copy:hover{
  background-color: rgb(255, 235, 252);
}

.swal2-custom-modal {
  background-color: #f9f9f9; /* Cor de fundo personalizada */
  border-radius: 10px;
  padding: 50px 20px;
}

.swal2-custom-modal .swal2-input {
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 20px 0;
}

.swal2-custom-modal .swal2-title {
  font-size: 24px;
  color: #333;
}

.swal2-custom-modal .swal2-confirm {
  background-color: #ad49e1 !important;
  color: #fff !important;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
}

.swal2-custom-modal .swal2-cancel {
  background-color: #f0f0f0 !important;
  color: #333 !important;
  padding: 10px 20px;
  border-radius: 5px;
}

.swal2-custom-modal .swal2-actions {
  display: flex;
  justify-content: flex-end; /* Alinha os botões à direita */
}

.swal2-custom-modal .swal2-actions .swal2-confirm {
  order: 1; /* Define a ordem do botão de confirmação */
}

.swal2-custom-modal .swal2-actions .swal2-cancel {
  order: 0; /* Define a ordem do botão de cancelamento */
}
.inputObs{
  min-height: 50px;
  max-height: 200px;
  height: 90px;
  width: 86%;
  resize: vertical;
  font-family: sans-serif;
  background-color: rgb(245, 245, 245);
  border: 0;
  border-radius: 2px;
  padding-top: 4px;
}

.btn-details[disabled] {
  font-style: italic;
  color: #A9A9A9; /* Cor cinza */
  cursor: not-allowed; /* Indica que o botão não é clicável */
  border-color: #d3d3d3; /* Bordas mais claras */
}

/* Remove o efeito de hover */
.btn-details[disabled]:hover {
  color: #A9A9A9;
  border-color: #d3d3d3;
}

.login-container{
  height: 45vh;
}

.h2acesse{
  color: #7D33A3;
}

.responsiveInput{
  width: 90%;
  padding-left: .3rem;
}


.responsiveLabel{
  width: 10%;
  min-width: 50px;
  text-align: left;
}
.tasks-table th:last-child{
  padding-left: 8px;
}

.textoAtt{
  width: 35%;
  margin: auto;
  text-align: left;
}

.attData{
  margin: auto;
  color: grey;
  font-style: italic;
  text-align: right;
}

.reset-password-container{
  width: 25%;
  display: inline-block;
  text-align: center;
}

.form-group2{
  justify-content: space-between;
  display: flex;
  margin-bottom: 1rem;
}

.titlepwreset{
  width:  8.5rem;
  text-align: left;
}

#password2, #confirmPassword2{
  width: 20rem;
  text-align: left;
}

.titleStatus{
  display: inline-flex;
  align-items: baseline;
}

#statusDropdown{
  height: 2em!important;
  margin: 0!important;
  margin-left: 1em!important;
  font-size: 1.2rem!important;
  width: 15rem!important;
}
.pagePlanoBg {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7D33A3;
}

.btn-back {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #7D33A3;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.a-btn-white {
  color: white;
}

.plano-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.card {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  width: 300px;
  text-align: center;
  color: rgb(63, 63, 63);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 300px;
  margin-bottom: 0px;
  margin-top: 20px;
  position: relative;
  z-index: 1;
}

.card:nth-child(2) {
  height: 350px;
  margin-bottom: 50px;
  margin-top: 0px;
  border-radius: 12px;
  position: relative;
  z-index: 1;
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 10px 3px rgba(255, 255, 255, 0.15);
  }
  50% {
    box-shadow: 0 0 18px 6px rgba(255, 255, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 10px 3px rgba(255, 255, 255, 0.15);
  }
}

.card h2 {
  margin-bottom: 20px;
}

.card ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.card li {
  margin-bottom: 10px;
}

.btn-plano {
  background-color: #AD49E1;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

#btn-planoMid{
margin-top: 20px;
}

.btn-plano:hover {
  background-color: #7D33A3;
}

.icon-bronze, .icon-prata, .icon-ouro {
  font-size: 10px;
  margin-bottom: 3px;
}

.icon-bronze {
  color: #cd7f32;
}

.icon-prata {
  color: #c0c0c0;
}

.icon-ouro {
  color: #ffd700;
}
