
body {
    overflow: hidden;
}

.shooting-stars {
    position: absolute;
    z-index: -2;
    top: 0;
    width: 100%;
    height: 100vh;
    transform: rotate(180deg);
}

@keyframes animateBg {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}

.shooting-stars span {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1), 0 0 0 8px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1);
    animation: animate 3s linear infinite;
}

.shooting-stars span::before {
    content: '';
    position: absolute;
    top: 0%;
    transform: translateY(-50%);
    width: 40vh;
    height: 1px;
    background: linear-gradient(90deg, #fff, transparent);
}

@keyframes animate {
    0% {
        transform: rotate(315deg) translateX(0);
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    100% {
        transform: rotate(315deg) translateX(-900px);
        opacity: 0;
    }
}

.shooting-stars span:nth-child(1) {
    top: 0;
    right: 0;
    left: initial;
    animation-delay: 8s;
    animation-duration: 8s;
}



.shooting-stars span:nth-child(6) {
    top: 0;
    right: 100vh;
    left: initial;
    animation-delay: 0.5s;
    animation-duration: 9s;
}


.shooting-stars span:nth-child(2) {
    top: -100px;
    right: 135vh;
    left: initial;
    animation-delay: 15s;
    animation-duration: 10s;
}


.shooting-stars span:nth-child(3) {
    top: 0px;
    right: 25vh;
    left: initial;
    animation-delay: 0s;
    animation-duration: 12s;
}